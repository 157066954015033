.footerParent{
    border-top: 3px solid white;
    height:auto;
    background-color: transparent;
    margin-top: 20px;
}

.footerDescribe , .footerText{
    color: white;
    display: block;
}
.footerText{
    font-size: 1.2rem;
}
.bootIcons{
    color: white;
    margin-left: 50px;
}
@media screen and (max-width:576px) {
   .footerText{
    font-size: 0.9rem;
   }
   .footerDescribe{
    font-size: 0.7rem;
   }
   .bootIcons{
    margin-left: 10px;
}
}

.row{
   margin: 60px 0;
   &:nth-child(2){
    margin-top: 0px;
   }
    a{
        text-decoration: none;
        color: white;
    }
}
.copyRight{
    border: 2px solid  white;
    border-radius: 30px;
    color:white;
    text-align: center;
    font-size: 0.7rem;
    margin-bottom: 30px;
}