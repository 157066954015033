.BodyParent{
    position: relative;
    max-width: 100%;
    background-color: transparent;
}
@media screen and (min-width:997px){
    .BodyParent{
        display: flex;
        justify-content: center;
    }
}

.showChoise{
    span , svg{
        color: white;
    }
}

.selectParent{
    margin-right: 20px;
    border-radius: 5px;
    text-align: center;
}


// paginate-------------------------------------
.paginateParent{
    position: absolute;
    width: 100%;
    z-index: 100;
    top: 100%;
}
.kindParent{
    form{
        width: 100%;
        margin-bottom:30px;
    }
    select{
        margin-right: 0;
        border-radius: 10px;
        outline: none;
    }
    label{
        color: white;
    }
}
.chooseTShirt{
    text-align: center;
    margin-bottom: 10px;
    color: white;
}
.paginate{
    direction: ltr;
    display: flex;
    justify-content: space-between;
    a{
        text-decoration: none;
        margin:0 5px;
    }
    list-style-type: none;
}