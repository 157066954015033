@import url("../styles/font.css");

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "iransanse"; 
}
.faNumber{
    font-family: "yekan";
}


@media (min-width: 992px){
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        right: 0;
        text-align: right;
    } 
}
.outerParent{
    height: auto;
    background-image:url('../images/Pattern.png'), linear-gradient(to bottom  ,#1a1e29 50%,90% ,#01c38e 100%  );

}

/* color pallet */
.rcp-body input{
    border: 1px solid white;
}
.rcp-interactive{
    border-radius: 15px;
}
.rcp-body{
    border-radius: 0 0 5px 5px;
}



/* for change text color when other hoverd in navbar */
.navbar-scrolling-dropdown:hover .nav-link{
    color: rgb(9,125,108) ;
}
.navbar-collapse {
        align-items: center;
        justify-content: end; 
}

