@keyframes notFoundMove {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.5);
    }
    100%{
        transform: scale(1);
    }
}
.notInner{
    img{
        display: none;
    }
    small{
        font-size: 1.3rem;
        font-weight: 700;
    }
}

@media screen and (min-width:768px) {
    .notInner{
        position: absolute;
        animation: notFoundMove 15s ease-in-out  infinite backwards ;
    }
    .notInner{
        img{
           display: block;
        }
    }
}

.notFoundParent{
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 11;
    background-color: white;
}