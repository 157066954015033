.avatarFrame span{
    text-align: center;
    margin-top: 10px;
}
.avatarFrame{
    z-index: 6;
    position: relative;
    a{
        text-decoration: none;
        color:black;
    }
}
.imgParent{
    overflow: hidden;
    text-align: center;
    z-index: 9;
    
    img{
        max-width: 100%;
        max-height: 80%;
        object-fit: fill;
        overflow-y: hidden;
        
    }
}
@keyframes zoomAnimation{
    from{
        transform: scale(1);
    }
    to{
        transform: scale(1.1);
    }
} 
.heart{
    visibility: hidden;
}
.avatarFrame{
    .imgParent{
        border-radius: 10px ;
    }
    &:hover .imgParent >img {
        animation-duration: 500ms;
        animation-name: zoomAnimation;
        animation-fill-mode: forwards;
        animation-direction: alternate;
    }

 &:hover .heart {
     visibility: visible;
 }
}

.heart:hover{
    z-index: 20; 
    scale: 1.2;
}

