.navParent{
    min-height: 10vh;
    position: relative;
    a{
        color: white;
    }
}
.navbarItem{
    color: black !important;
}
.linkAdd:hover {
   color: rgb(9,125,108) !important;
   font-weight: normal !important;
}

.navContainer{
    position: relative;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.logo{
    margin-right:-20px !important;
    position: relative;
}
.aboutUs{
    width: 81%;
    justify-content: start;
  a{
        font-size: 1rem;
        font-weight: normal;
        margin-left: 5%;
        font-size: 1.1rem;
    }
}
@media screen and (min-width:992px) {
    .aboutUs{
        height: 100%;
    }
    .search{
        margin-right: auto;
    }
}

// dark mood--------------------------------------------
// @keyframes darkMove {
//     from{
//         top: -50px;
//     }
//     to{
//         top:21px;
//     }
// }
// @keyframes darkMoveSM {
//     from{
//         top: -50px;
//     }
//     to{
//         top:15px;
//     }
// }
// .brightnessParent{
//     position: absolute;
//     left: 10px;
//     top: -100%;
//     animation: darkMove 500ms linear 300ms 1 forwards ;
// }
// @media screen and (max-width :992px) {
//     .brightnessParent{
//         left: 50%;
//         animation: darkMoveSM 500ms linear 300ms 1 forwards ;
//     }
  
// }

