.chooseParent{
    width: 100%;
    min-height: 70vh;
    padding-bottom: 30px;
}

.colorParent{
    margin-top: 10px;
    border: 1px solid white;
    border-radius: 10px;
    div{   
        background-color: transparent;
        input{
            color: white;
        }
    }
}

.showAPIParent{
    border: 1px solid yellow;
    height: 100%;
}
.showAPI{
    border: 1px solid green;
    height: 100%;
}

@media screen and (max-width:768px) {
    .colorParent{
        display: none;
    }
    
}
@media screen and (max-width:1200px) {
    .formParent{
        margin-top: 50px;
    }
}
.container{
    height: 100%;
}
.imgContainer{
    img{
        height: 100%;
        object-fit: cover;
    }
}
.imgParent{
    height: 100%;
    position: relative;
    background-color: transparent;
}
.avatarImg{
    border-radius: 10px;
}
.sideImage{
     div{
        margin-top: 20px;
     }
     div:nth-child(1){
        margin-top: 0;
     }
     span{
        color: white; 
        font-size: 0.8rem;
        margin-right: 5px;
    }
    .BTS{
        color: white;
    }

}

@media screen and (min-width:992px) {
    .BTS{
        font-size: 30px;
    }
}
@media screen and (max-width:992px) {
    .sideImage{
        div{
            margin-top: 0;
            margin-bottom: 20px;
        }
       span{
            font-size: 0.7rem;
        }
        .BTS{
            font-size: 20px;
        }
     
    }
}
@media screen and (min-width:576px) {
  .sideImage{
    flex-direction: column;
    justify-content: end;
  }
    
}
@media screen and (max-width:576px) {
    .sideImage{
        flex-direction:row;
        justify-content:space-between;
        margin-bottom: 10px;
    }
    .imgContainer{
        flex-wrap: wrap;
        justify-content: center;
        img{
            height: 85%;    
        }
    }
    
}
// input type file----------------------------------
.customFileInput{
    color: transparent;
    max-height: 50px;
}
.customFileInput::-webkit-file-upload-button{
    visibility: hidden;
    opacity: 0;
    border: 1px solid #000;
}

.customFileInput::before{
    content: 'انتخاب لباس';
    text-align: center;
    display:block;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 30px;
    padding: 10px 8px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-weight: normal !important;
    color:white
}
.customFileInput::before{
    font-size: 1rem;
    font-family: 'iransanse';
    font-weight: bold;
}


.customFileInput:active::before{
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

@media screen and (max-width:576px) {
    .customFileInput{
        margin-top: 30px;
    }
}
.btn{
    margin: 8px 0;
    border-radius: 30px;
    border: none ;
    color:white;
    border: 1px solid white;
    background-color: transparent;
    padding:10px 8px;
    font-weight: normal;
    text-decoration: none;
    text-align: center;
 
}



.btn:active{
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
// preview image-------------------------------------------
.imgPreview{
    img{
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }
}
.fileName{
    color:white;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;
}
.spinner{
    color: white;
}

// error -----------------------------------------
@keyframes errorColor  {
    0%{
        color:#09816E;
    }
    
    100%{
        color: red;
    }
    
} 
    

.error{
    width: 100%;
}
.showErr{
    color:red;
    font-size: 1.4rem;
    text-align: center;  
    animation-name: errorColor;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

